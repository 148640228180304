import { getDetails,getSeckillGoods } from '@/api/home'

// 获取浏览器链接中的参数
export function getParameterByName(name) {
  const url = window.location.hash;
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(url);
  if (results === null) {
    return '';
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}

// 校验热卖商品是否已下架
export async function validateGoodStatus(id) {
  const res = await getDetails(id)
  console.log(res,'------------');
  if(res.data.error === '500'){
    return true
  }else{
    return false
  }
}
export async function validateSeckillGoodStatus(id) {
  const res = await getSeckillGoods(id)
  if(res.data.error === '500'){
    return true
  }else{
    return false
  }
}


